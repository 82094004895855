import Product from "../Products"
import { Button, Offcanvas, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "./utils/formatCurrency"
import { CartItem } from "./CartItem"
import { useNavigate } from "react-router-dom"
import "../style/Product.css";
import React, { useContext } from 'react';
import { LanguageContext } from '../containers/Language';
import { CurrencyContext } from "../containers/Currency";
import "../style/ShoppingCart.css";

type ShoppingCartProps = {
  isOpen: boolean
}

// type CurrencyFx = {
//   currencyFx: number
// }

export function ShoppingCart({ isOpen }: ShoppingCartProps) {
  const { closeCart, cartItems, clearCart } = useShoppingCart()
  const storeItems: any[] = Product();
  const { dictionary } = useContext(LanguageContext);
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
  function limpa() {
    if (window.confirm(dictionary.Cart.CleanCart) === true) {
      clearCart();
    }
  }

  let navigate = useNavigate();

  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement="end" className="canvas">
      <Offcanvas.Body>
        <Stack gap={3}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{dictionary.Cart.Cart}</Offcanvas.Title>
          </Offcanvas.Header>

          <div className="container">
            {cartItems.map(item => (
              <div key={item.id} className="me-auto checkButton">
                <CartItem {...item} />
              </div>))}
            <br />
          </div>
          <div className="ms-auto fw-bold fs-5">
            {dictionary.Cart.Total}{" "}
            {formatCurrency(
              cartItems.reduce((total, cartItem) => {
                const item = storeItems.find(i => i._id.$oid === cartItem.id)
                return total + ((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * cartItem.quantity * currencyFactor || 0)
              }, 0)
              , currencySymbol)}
          </div>
          <div className="outer">
            <div className="inner-start">
              <Button className="btncolor" onClick={() => {
                navigate("/checkout", { replace: true })
                closeCart()
              }}>
                {dictionary.Cart.Checkout}
              </Button>
            </div>
            <div className="inner-end">
              <Button className="btn-danger" onClick={() => { limpa() }}>
                {dictionary.Cart.ClearCart}
              </Button>
            </div>
          </div>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  )
}