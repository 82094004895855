import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "../style/Carousel.css";
const Carouselist = (props) => {
  const defaultLanguage = window.localStorage.getItem('netsystems-languages') || process.env.REACT_APP_LANGUAGE;
  return (
    <Carousel>
      {props.carousels &&
        props.carousels.map((carousel) => {
          return (
            <Carousel.Item key={carousel._id.$oid}>
              <img
                className="d-block w-100 Carousel"
                src={carousel.image}
                alt={carousel.alt[defaultLanguage]}
              />
              <Carousel.Caption>
                <h5 className="text-start bigtext">{carousel.title[defaultLanguage]}</h5>
                <h1 className="text-start bigtext">{carousel.subtitle[defaultLanguage]}</h1>

                <div className="text-start bigtext">{carousel.body[defaultLanguage]}</div>

              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

export default Carouselist;
