import React, { useState, useContext } from "react";
import { useShoppingCart } from "../context/ShoppingCartContext"
import { CartItem } from "../components/CartItem"
import { Product } from "../Products"
import { formatCurrency } from "../components/utils/formatCurrency";
import { Button } from "react-bootstrap"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import Paypal from "../components/Paypal";
import axios from "axios";
import "../style/Paypal.css";
import "../style/Checkout.css";
import { CurrencyContext } from "../containers/Currency";


function Checkout() {
    const storeItems = Product();
    const { cartItems } = useShoppingCart();
    const [message, setMessage] = useState('');
    const [discount, setDiscount] = useState();
    const [error, setError] = useState(0); // 0 = Starting
    // eslint-disable-next-line
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [data, setData] = useState([]);
    const { currencyFactor, currencySymbol } = useContext(CurrencyContext);


    const handleClick = async () => {
        const fetchCoupons = async () => {
            await axios
                .get(`${process.env.REACT_APP_BACKEND}coupon/${message}`)
                .then((res) => {
                    setData(res.data)
                    setDiscount(res.data.map(item => (item?.discount)))
                    if (res.data.map(item => (item.discount)) === undefined) {
                        setError(1); // NO ITEMS
                    }
                    else {
                        setError(2) // NO ERROR
                    }
                })
                .catch(() => setError(3)); //ERROR IN API
        };
        await fetchCoupons();

    };
    const handleChange = event => {
        event.preventDefault();
        setMessage(event.target.value);
    };

    const totalx = cartItems.reduce((totalxx, cartItem) => {
        const item = storeItems.find(i => i._id.$oid === cartItem.id);
        // (totalxx + (item?.product.price || 0) * cartItem.quantity)
        return (totalxx + ((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor * cartItem.quantity || 0));
    }, 0)



    return (
        <>
            <Navbar link="/checkout" title={process.env.REACT_APP_COMPANY_NAME + " - Pagamento"} name="Checkout" />
            <Breadcrumb link="/checkout" title={process.env.REACT_APP_COMPANY_NAME + " - Pagamento"} name="Checkout" />
            <div className="container" id="tblOrder">
                {cartItems.map(item => (
                    <div key={item.id} className="me-auto checkButton adjust">
                        <CartItem {...item} />
                    </div>))}
            </div>

            <div className="container coupon" id="coupon">
                {discount > 0 ? <>
                    <div className="container coupon discount row">Coupon: {message}</div>
                    <div className="container coupon discount row">Applied discount: {formatCurrency(discount, currencySymbol)}</div>
                    <div className="container coupon discount row">Total: {formatCurrency(totalx - discount, currencySymbol)}</div></>
                    : <div className="container d-flex gap-3 align-items-center me-auto d-flex gap-3 align-items-center">
                        <div className="coupon discount row">Total {formatCurrency(totalx, currencySymbol)}</div>
                    </div >}
                {error === 1 ? <div className="coupon error row">Coupon not found:{message}</div> : <></>}
                {discount === undefined || error === 0 ? <>
                    <label id="couponLabel">Coupon:</label>
                    <input autoFocus type="text" onChange={handleChange} autoComplete="off" />
                    <Button onClick={handleClick} variant="success" size="sm">Apply coupon</Button></> : <></>}
            </div>
            <Paypal />
            <Footer name="Checkout" />
        </>
    );
}

export default Checkout;
