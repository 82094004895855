import React, { createContext, ReactNode, useContext, useState } from "react"
import { ShoppingCart } from "../components/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  id: string
  _id?: any
  quantity: number
}

type ShoppingCartContext = {
  openCart: () => void
  clearCart: () => void
  closeCart: () => void
  getItemQuantity: (id: string) => number
  increaseCartQuantity: (id: string) => void
  decreaseCartQuantity: (id: string) => void
  removeFromCart: (id: string) => void
  cartQuantity: number
  cartItems: CartItem[]
}

// type LoginContext = {
//   token: string
//   removeToken: () => void
//   setToken: () => void
// }

const ShoppingCartContexts = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContexts)
}
export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {


  const [isOpen, setIsOpen] = useState(false)
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>("shopping-cart", [])

  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const openCart = () => setIsOpen(true)
  const closeCart = () => setIsOpen(false)
  function clearCart() {
    cartItems.map(item => {
      for (let y = 0; y <= item.quantity; y++) {
        decreaseCartQuantity(item.id)
      }
      closeCart()
      return (item)
    }
    )
  }
  function getItemQuantity(id: string) {
    return cartItems.find(item => item.id === id)?.quantity || 0
  }
  function increaseCartQuantity(id: string) {
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id) == null) {
        return [...currItems, { id, quantity: 1 }]
      } else {
        return currItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decreaseCartQuantity(id: string) {
    setCartItems(currItems => {
      if (currItems.find(item => item.id === id)?.quantity === 1) {
        if (currItems.filter(item => item.id !== id) && cartItems.length === 1) {
          closeCart();
        }
        return currItems.filter(item => item.id !== id)
      } else {
        return currItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function removeFromCart(id: string) {
    setCartItems(currItems => {
      if (currItems.filter(item => item.id !== id) && cartItems.length === 1) {
        closeCart();
      }
      return currItems.filter(item => item.id !== id)
    })
  }

  return (
    <>
      <ShoppingCartContexts.Provider
        value={{
          getItemQuantity,
          increaseCartQuantity,
          decreaseCartQuantity,
          removeFromCart,
          clearCart,
          openCart,
          closeCart,
          cartItems,
          cartQuantity,
        }}
      >
        {children}
        <ShoppingCart isOpen={isOpen} />
      </ShoppingCartContexts.Provider>
    </>
  )
}