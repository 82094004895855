import { useState, useEffect } from "react";

export function Product() {

  const [products, setProduct] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND + "/product", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setProduct(response)
      })
      .catch((error) => console.log(error));
  }, []);
  // console.log(products);
  return products;
}

export default Product;
