import React, { useState } from "react";
import { Button } from "react-bootstrap"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
// STYLES //
import "../style/Import.css";
import { formatCurrency } from "../components/utils/formatCurrency";

function Import() {
    // const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
    const [error, setError] = useState(0); // 0 = Starting
    const [data, setData] = useState([{ "name": "test", "produ": "fake" }]);
    // const [icon, setIcon] = useState([]);
    const [message, setMessage] = useState([]);

    const handleClick = async () => {
        const fetchData = async () => {
            await axios
                .get(`${process.env.REACT_APP_BACKEND}scrap/${message}`)
                .then((res) => {
                    setData(res.data)
                    if (res.data.map(product => (product._id)) === undefined) {
                        setError(1); // NO ITEMS
                    }
                    else {
                        setError(2) // NO ERROR
                    }
                })
                .catch(() => setError(3)); //ERROR IN API
        };
        await fetchData();

    };
    const handleChange = event => {
        event.preventDefault();
        setMessage(event.target.value);
    };
    return (
        <>
            <Navbar link="/import" title={process.env.REACT_APP_COMPANY_NAME + " - Pagamento"} name="Import" />
            <Breadcrumb link="/import" title={process.env.REACT_APP_COMPANY_NAME + " - Pagamento"} name="Import" />
            <div className="container coupon" id="import">
                {error === 1 ? <div className="error row">Coupon not found:{message}</div> : <></>}
                {data === undefined || error === 0 ?
                    <div>
                        <div>
                            For example: https://www.aliexpress.us/item/<h1>1005005060703526</h1>.html
                            Use the code from the example above.
                        </div>
                        <label id="couponLabel">Product code from AliExpress:</label>
                        <input autoFocus type="text" onChange={handleChange} autoComplete="off" />
                        <Button onClick={handleClick} variant="success" size="sm">Check Product</Button>
                    </div> :
                    <div>
                        <div>
                            <div className="container">
                                <div className="product_image">
                                    <a href="/"><img src={data.product.image} alt='Hello Bitch'></img></a>
                                </div>
                            </div>
                            <div>Product name: {data.product.name}</div>
                            <div>ESTRELAS media: {data.product.feedback.averageStar}</div>
                            <div>Five star: {data.product.feedback.fiveStarNum}</div>
                        </div>
                        {data.product.variants.map &&
                            data.product.variants.map((product) => {
                                return (
                                    <div key={product.id}>
                                        <img
                                            className="d-block w-100 Carousel"
                                            src={product.images.big_image}
                                            alt={data.product.name + ' ' + product.name}
                                        />{product.name} ID: {product.id} QTD: {product.available} Total: {formatCurrency(product.full_price, product.currency)}
                                    </div>
                                );
                            })}
                    </div>
                }
            </div>
            <Footer name="Import" />
        </>
    );
}

export default Import;
