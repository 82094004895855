import Link from "./Link";
import { useState, useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { Product } from "../Products";
import { CurrencyContext } from "../containers/Currency";
// import { CartItem } from "./CartItem";
// import { formatCurrency } from "./utils/formatCurrency";

import "../style/Paypal.css";

export default function App() {
    const [success, setSuccess] = useState(false);
    const [paymentProblems, setPaymentProblems] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState([]);
    const [dataID, setDataID] = useState([]);
    const { cartItems, clearCart } = useShoppingCart();
    const storeItems = Product();
    const [orders, setOrders] = useState(false);
    let tot = 0
    let items = []
    let item = []
    const defaultLanguage = window.localStorage.getItem('netsystems-languages');
    const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
    function ae() {
        try {
            if (cartItems[0].quantity > 0) {
                tot = cartItems.reduce((total, cartItem) => {
                    item = storeItems.find(i => i._id.$oid === cartItem.id)
                    items.push({
                        "sku": item?.product.sku,
                        "name": item?.product.name[defaultLanguage],
                        "quantity": cartItem?.quantity.toFixed(0),
                        // "amount": tot,
                        "unit_amount": {
                            "currency_code": currencySymbol,
                            "value": ((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor).toFixed(2)
                        },
                        "description": item?.product.name[defaultLanguage]
                    })
                    return total + parseFloat(String((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor * cartItem.quantity))
                }, 0)
            }
        } catch (error) {
            console.log(error)
            window.location.assign('/');
        }
    }
    ae()
    function PayPalItems() {
        if (tot > 0) {
            items.amount = tot;
            return items;
        }
    }
    PayPalItems()

    // creates a paypal order

    const createOrder = (data, actions) => {
        if (tot > 0) {
            return actions.order.create({
                "purchase_units": [{
                    "amount": {
                        "currency_code": currencySymbol,
                        "value": items.amount.toFixed(2),
                        "breakdown": {
                            "item_total": {
                                "currency_code": currencySymbol,
                                "value": items.amount.toFixed(2)
                            },
                        },
                    },
                    "items": PayPalItems()
                }
                ]
            })
        };
    }
    function writeOrder(order) {
        fetch(process.env.REACT_APP_PAYMENT_ADDRESS, {
            body: JSON.stringify(order),
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then((response) => response.json())
            .then((response) => setOrders(response))
            .then(console.log(JSON.stringify(orders)))
            .catch((error) => console.log(error));
    }
    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            document.getElementById("tblOrder").classList.add("disable-div");
            document.getElementById("coupon").classList.add("disable-div");
            setOrderID(payer);
            setDataID(data);
            setSuccess(true);
            cartItems.reduce((total, cartItem) => {
                item = storeItems.find(i => i._id.$oid === cartItem.id)
                items.push({
                    "sku": item?.product.sku,
                    "name": item?.product.name[defaultLanguage],
                    "quantity": cartItem?.quantity.toFixed(0),
                    // "amount": tot,
                    "unit_amount": {
                        "currency_code": currencySymbol,
                        "value": ((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor).toFixed(2)
                    },
                    "description": item?.product.name[defaultLanguage]
                })
                return total
            }
            )
            writeOrder({ 'data': data, 'payer': payer, 'product': items });
            console.log(cartItems.length())

            // LIMPAR CARRINHO NESSA MERDA!
        });
    };
    const onError = (data) => {
        setErrorMessage("An Error occured with your payment! Info: " + data);
        setPaymentProblems(true);
        console.log(ErrorMessage);
    };
    if (success === false)
        return (
            <>
                {paymentProblems ? <h1 className="payment-problem">Payment problems, please retry. {ErrorMessage}</h1> : <></>}
                {tot > 0 ? (
                    <PayPalScriptProvider
                        options={{
                            "client-id": process.env.REACT_APP_PAYPAL_ID,
                            "currency": currencySymbol
                        }}
                    >
                        <div className="container">
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                onError={onError}
                                forceReRender={[cartItems, currencySymbol]}
                            />
                        </div>
                    </PayPalScriptProvider >
                ) : null}
            </>
        );
    else {
        if (orderID !== false) {
            clearCart()
            return (
                <div className="container ordered">
                    Thanks for your order, {orderID.name?.given_name} {orderID.name?.surname}.<br />
                    Country to delivery to: {orderID.address?.country_code}<br />
                    Your Paypal ID is {orderID?.payer_id}<br />
                    Your Data ID is {JSON.stringify(dataID)}<br />
                    It will arrive soon more informations on your e-mail: {orderID?.email_address}.<br />
                    {/* {orders ? 'ORDERS: ' + JSON.stringify(orders) : ''} */}

                    {console.log(JSON.stringify(orders))}
                    <div>
                        <Link page="Home" to={'/'}>Home</Link>
                    </div>
                </div>
            )
        }
    }
}