import React, { useState, createContext, useContext } from 'react';
import { languageOptions, dictionaryList } from '../data/languages';

export const LanguageContext = createContext({
    userLanguage: process.env.REACT_APP_LANGUAGE,
    dictionary: dictionaryList[process.env.REACT_APP_LANGUAGE]
});
// it provides the language context to app
export function LanguageProvider({ children }) {
    const defaultLanguage = window.localStorage.getItem('netsystems-languages');
    const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'pt');
    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: selected => {
            const newLanguage = languageOptions[selected] ? selected : 'pt'
            setUserLanguage(newLanguage);
            window.localStorage.setItem('netsystems-languages', newLanguage);
        }
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );

};

// get text according to id & current language
export function Text({ tid }) {
    const languageContext = useContext(LanguageContext);
    return languageContext.dictionary[tid] || tid;
};