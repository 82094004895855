import "../style/Product.css";
import { Button } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "./utils/formatCurrency"
import Link from "../components/Link"
import React, { useContext } from 'react';
import { LanguageContext } from '../containers/Language';
import { CurrencyContext } from "../containers/Currency";

export default interface StoreItemProps {
  _id: any;
  product:
  {
    name: { [key: string]: string };
    price: number
    images: { [key: string]: any };
    subtitle: string
    body: string
    margin: number
    link: string
    date: string
    quantity: number
    cost: number
    keywords: string
  },
  category: { [key: string]: string },
  subcategory:
  {
    id: number
    name: { [key: string]: string };
    cod_categoria: number
  }
}

export function StoreItem({ _id, product, category }: StoreItemProps) {
  const defaultLanguage = window.localStorage.getItem('netsystems-languages') || "pt";
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
  const { dictionary } = useContext(LanguageContext);
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
  } = useShoppingCart()
  const quantity = getItemQuantity(_id.$oid)
  const { openCart, cartQuantity } = useShoppingCart()

  return (
    <div className="container" key={_id.$oid}>
      <Link to={'/product/' + product.name?.["en"]}>
        <img
          src={'/static/images/products/' + product.images?.main.src}
          className="card-img-top pietro"
          alt={product.images?.main.alt?.[defaultLanguage]}
        />
      </Link>
      <div className="card-body">
        <h5 className="card-title text-center cardName">
          {String(product.name[defaultLanguage]).length < 20 ? product.name[defaultLanguage] : product.name[defaultLanguage].substring(0, 20)}
        </h5>
        <h5 className="card-title text-center hide">
          {product.name[defaultLanguage]}
        </h5>
        <h6 className="card-subtitle text-center">
          {/* {product.body[defaultLanguage]} */}
        </h6>
        <div className="row">
          <div className="col text-center">{formatCurrency((product.cost * product.margin / 100 + product.cost) * currencyFactor, currencySymbol)}</div>
        </div>
        {product.quantity > 0 ?
          <div className="row">
            <div className="col text-center">
              {quantity === 0 ? (
                <Button className="w-100 btncolor btnsize" onClick={() => increaseCartQuantity(_id.$oid)}>
                  {dictionary.Cart.AddToCart}
                </Button>
              ) : (
                <div
                  className="d-flex align-items-center flex-column"
                  style={{ gap: ".5rem" }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ gap: ".5rem" }}
                  >
                    <Button className="btncolor" onClick={() => decreaseCartQuantity(_id.$oid)}>-</Button>
                    <div>
                      <span className="fs-3">{quantity}</span> {dictionary.Cart.OnCart}
                    </div>
                    <Button className="btncolor" onClick={() => increaseCartQuantity(_id.$oid)}>+</Button>
                  </div>
                  <Button
                    onClick={() => removeFromCart(_id.$oid)}
                    variant="secondary"
                    size="sm"
                  >
                    {dictionary.Cart.DelFrCart}
                  </Button>
                  <Button
                    onClick={openCart}
                    style={{ width: "3rem", height: "3rem", position: "relative" }}
                    className="rounded-circle btncolor"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      fill="currentColor"
                    >
                      <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                    </svg>

                    <div
                      className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                      style={{
                        color: "white",
                        width: "1.5rem",
                        height: "1.5rem",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        transform: "translate(25%, 25%)",
                      }}
                    >
                      {cartQuantity}
                    </div>
                  </Button>

                </div>

              )

              }
              <div className="outer">
                <div className="inner">
                  <img className="secondary zoom"
                    src={'/static/images/products/' + product?.images.secondary.src}
                    alt={product.images?.secondary.alt?.[defaultLanguage]}
                  />
                </div>
                <div className="inner">
                  {product.images?.other?.src?.map((item: string, index: number) => (
                    <div key={index}>
                      <img className="other-imgs zoom" src={'/static/images/products/' + product?.images?.other?.src[index]}
                        alt={product?.images?.other?.alt?.[defaultLanguage]} /></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          :
          <div className="d-flex align-items-center justify-content-center">
            <Button className="w-100 btn-danger sold-out btnsize">{dictionary.Cart.SoldOut}</Button>
          </div>
        }
      </div>
    </div >
  );
};
