import React, { useContext } from 'react';
import Product from "../Products"
import { Button, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "./utils/formatCurrency"
import { Row } from "react-bootstrap"
import "../style/Checkout.css";
import { CurrencyContext } from "../containers/Currency";
// import { LanguageContext } from '../containers/Language';

type CartItemProps = {
  id: string
  quantity: number
}


export function CartItem({ id, quantity }: CartItemProps) {
  const defaultLanguage = (window.localStorage.getItem('netsystems-languages') || process.env.REACT_APP_LANGUAGE) || 'pt';
  const storeItems: any[] = Product();
  const { decreaseCartQuantity, increaseCartQuantity } = useShoppingCart()
  const item = storeItems.find(i => i._id.$oid === id)

  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);

  if (item === undefined) return null
  return (
    <Stack direction="horizontal" gap={3} className="d-flex align-items-center">
      <img
        src={'/static/images/products/' + item.product.images.main.src}
        alt={item.product?.images.main.alt[defaultLanguage]}
        className="imageCheckout"
      />
      <Row className="me-auto">
        {item.product.name[defaultLanguage] ? item.product.name[defaultLanguage] : "eu"}
        {quantity > 1 && (
          <span className="text-muted" style={{ fontSize: ".65rem" }}>
            x{quantity}
          </span>
        )}
        <Row className="text-muted" style={{ fontSize: ".75rem" }}>
          {formatCurrency((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor, currencySymbol) || 0}
        </Row>
      </Row>
      <Button
        className="btncolor"
        size="sm"
        onClick={() => increaseCartQuantity(item._id.$oid)}
      >+</Button>
      <Row>{formatCurrency((item?.product.cost * item?.product.margin / 100 + item?.product.cost) * currencyFactor * quantity, currencySymbol) || 0}</Row>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => decreaseCartQuantity(item._id.$oid)}
      >
        -
      </Button>
    </Stack>
  )
}
