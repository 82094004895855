import React, { useState, useEffect } from "react";
import CountryNavList from "./components/CountryNavList";
import axios from "axios";

function ProductNav() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);
  const fetchProducts = () => {
    axios
      .get(process.env.REACT_APP_BACKEND + "product")
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      }
      );
  }

  return <CountryNavList products={products} />;
}


export default ProductNav;
