import "../style/Faq.css";
import Link from "./Link";
import React, { useContext } from 'react';
import { LanguageContext } from '../containers/Language';


const LocationNavList = (props) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <ul className="navbar-nav">
        <li className="nav-item navbar-light dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="/"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {dictionary.Menu.Locations}
          </a>
          {JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("black") ||
            JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#000") ||
            JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#000000") ?
            <ul
              className="dropdown-menu"
              style={{ "backgroundColor": "black" }}
              aria-labelledby="navbarDropdownMenuLink"
            >
              <ul className="Faq">

                <li className="nav-link">

                  <Link
                    page={props.location.country_name}
                    to={'/location/' + props.location.country_name}
                    pass="0"
                  >
                    {props.location.country_name}
                  </Link>
                </li>
              </ul>
            </ul>
            :
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <ul className="Faq">

                <li className="nav-link">
                  <Link
                    page={props.location.country_name}
                    to={'/location/' + props.location.country_name}
                    pass="0"
                  >
                    {props.location.country_name}
                  </Link>
                </li>

              </ul>
            </ul>}
        </li>
      </ul >
    </>
  );
};

export default LocationNavList;
