// import React, { useContext } from 'react';
// import { LanguageContext } from '../containers/Language';
// const { dictionary } = useContext(LanguageContext);
import React, { useContext } from 'react';
import { useShoppingCart } from "../context/ShoppingCartContext";
import { Button } from "react-bootstrap";
import Link from "./Link";
import ProductNav from "../ProductNav";
import LocationNav from "../LocationNav";
import useToken from './useToken'
import Logout from "./Logout"
import Login from "./Login"
import "../style/Navbar.css";
import { LanguageContext } from '../containers/Language';

const Navbar = (props) => {
  const { dictionary } = useContext(LanguageContext);
  const { token, removeToken, setToken } = useToken();
  const { openCart, cartQuantity } = useShoppingCart();

  document.title = props.title;
  return (
    <>
      {
        JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("black")
          || JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#fff") ||
          JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#ffffff") ?

          <nav className="Navbar navbar navbar-expand-lg navbar-light container-fluid">
            <div className="container">
              <Link nome="navbar-brand" to="/">
                {/* <a className="navbar-brand"> */}
                <img
                  alt={process.env.REACT_APP_COMPANY_NAME}
                  src={process.env.REACT_APP_MAIN_LOGO}
                  width={process.env.REACT_APP_MAIN_LOGO_WIDTH}
                  height={process.env.REACT_APP_MAIN_LOGO_HEIGHT}
                />
              </Link>

              <button
                className="navbar-toggler"
                typex="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.Home}
                      to="/"
                    >{dictionary.Menu.Home}
                    </Link>
                  </li>

                  <ProductNav />
                  <LocationNav />
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.About}
                      to={"/about"}
                    >
                      {dictionary.Menu.About}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.FAQ}
                      to="/faq"
                    >
                      {dictionary.Menu.FAQ}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.Contact}
                      to="/contact"
                    >
                      {dictionary.Menu.Contact}
                    </Link>
                  </li>
                  {!token && token !== "" && token !== undefined ?
                    <>
                      <li className="nav-item small">
                        <Login setToken={setToken} />
                      </li>
                    </> : (
                      <>
                        <li className="nav-item ">
                          <Link
                            page={props.name?.toLowerCase()}
                            nome={dictionary.Menu.Authors}
                            to="/author">
                            {dictionary.Menu.Authors}
                          </Link>
                        </li>
                        <Logout token={removeToken} />
                      </>
                    )}
                  <li className="nav-item">
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                    <a href="https://wa.me/34657107858?text=I%20would%20like%20to%20talk%20about%20your%20hiring..." className="whatsapp" target="_blank" rel="noreferrer"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>
                  </li>
                </ul>
              </div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href={"tel:" + process.env.REACT_APP_PHONE}><i className="fa fa-phone" aria-hidden="true"></i>{process.env.REACT_APP_PHONE}</a>
                </li>
              </ul>

              <ul className="navbar-nav">
                <li className="nav-item">
                  {cartQuantity > 0 && (
                    <Button
                      onClick={openCart}
                      style={{ width: "3rem", height: "3rem", position: "relative" }}
                      className="rounded-circle btncolor"
                      id="cart"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        fill="currentColor"
                      >
                        <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                      </svg> <div
                        className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                        style={{
                          color: "white",
                          width: "1.5rem",
                          height: "1.5rem",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "translate(25%, 25%)",
                        }}
                      >
                        {cartQuantity}
                      </div>
                    </Button>
                  )}
                </li>
              </ul>
            </div>

          </nav>
          :
          <nav className="Navbar navbar navbar-expand-lg navbar-dark container-fluid">
            <div className="container">
              <Link pass="1" nome="navbar-brand" to="/">
                <img
                  src={process.env.REACT_APP_MAIN_LOGO}
                  alt={process.env.REACT_APP_COMPANY_NAME}
                  width={process.env.REACT_APP_MAIN_LOGO_WIDTH}
                  height={process.env.REACT_APP_MAIN_LOGO_HEIGHT}
                />
              </Link>

              <button
                className="navbar-toggler"
                typex="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.Home}
                      to="/"
                    >
                      {dictionary.Menu.Home}
                    </Link>
                  </li>
                  <ProductNav />
                  <LocationNav />
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.About}
                      to="/about"
                    >
                      {dictionary.Menu.About}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.FAQ}
                      to="/faq"
                    >{dictionary.Menu.FAQ}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link page={props.name?.toLowerCase()}
                      nome={dictionary.Menu.Contact}
                      to="/contact"
                    >
                      {dictionary.Menu.Contact}
                    </Link>
                  </li>
                  {!token && token !== "" && token !== undefined ?
                    <>
                      <li className="nav-item small">
                        <Login setToken={setToken} />
                      </li>
                    </>
                    : (
                      <>
                        <li className="nav-item">
                          <Link page={props.name?.toLowerCase()}
                            nome={dictionary.Menu.Authors}
                            to="/author">
                            {dictionary.Menu.Authors}
                          </Link>
                        </li>
                        <li className="nav-item small">
                          <Logout token={removeToken} />
                        </li>
                      </>
                    )}

                  <li className="nav-item">
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                    <a href="https://wa.me/34657107858?text=I%20would%20like%20to%20talk%20about%20your%20hiring..." className="whatsapp" target="_blank" rel="noreferrer"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>
                  </li>
                </ul>
              </div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href={"tel:" + process.env.REACT_APP_PHONE}><i className="fa fa-phone" aria-hidden="true"></i>{process.env.REACT_APP_PHONE}</a>
                </li>
              </ul>

              <ul className="navbar-nav">
                <li className="nav-item">
                  {cartQuantity > 0 && (
                    <Button
                      onClick={openCart}
                      style={{ width: "3rem", height: "3rem", position: "relative" }}
                      className="rounded-circle btncolor"
                      id="cart"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        fill="currentColor"
                      >
                        <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                      </svg> <div
                        className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                        style={{
                          color: "white",
                          width: "1.5rem",
                          height: "1.5rem",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "translate(25%, 25%)",
                        }}
                      >
                        {cartQuantity}
                      </div>
                    </Button>
                  )}
                </li>
              </ul>
            </div>
          </nav>
      }
    </>
  );
};

export default Navbar;
