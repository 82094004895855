import { Col, Row } from "react-bootstrap"
import { StoreItem } from "./StoreItem"
import Product from "../Products";
export interface StoreProps {
    product: any[]
    category: any[]
    subcategory: any[]
}

export default interface StoreItemProps {
    id: string
    sellable: boolean
}

export function Store() {
    const storeItems: any[] = Product();
    return (
        <Row md={1} xs={1} lg={3} className="g-3">
            {storeItems.map(item => (
                <div key={item._id.$oid}>
                    {
                        item.sellable ? <Col>
                            <StoreItem {...item} />
                        </Col> : <></>
                    }
                </div>
            ))
            }
        </Row >
    )
}