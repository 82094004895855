import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from '../containers/Language';
import { useParams } from "react-router-dom";
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatCurrency } from "../components/utils/formatCurrency"
import { Button } from "react-bootstrap"
import axios from "axios";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Link from "../components/Link"
import Navbar from "../components/Navbar";
import "../style/Product.css";
import { CurrencyContext } from "../containers/Currency";

// const currencyFactor = parseFloat(String(window.localStorage.getItem('netsystems-currency-fx'))) || 1
// const currencySymbol = String(window.localStorage.getItem('netsystems-currency')) || process.env.REACT_APP_CURRENCY_SYMBOL || 'BRL'

const Product = () => {
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
  const { dictionary } = useContext(LanguageContext);
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
  } = useShoppingCart()
  const { id } = useParams();
  const { openCart, cartQuantity } = useShoppingCart()
  const [data, setData] = useState([]);
  const defaultLanguage = window.localStorage.getItem('netsystems-languages') || "pt";

  useEffect(() => {
    fetchProduct(id);
  }, [id]);
  const fetchProduct = (id) => {
    if (id !== undefined) {
      axios
        .get(`${process.env.REACT_APP_BACKEND}product/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
    else {
      axios
        .get(`${process.env.REACT_APP_BACKEND}product`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }

  if (data.length > 0 && id !== undefined) return (
    <>
      <Navbar link={"/" + dictionary.Product.Product} title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Product.Product} name={dictionary.Product.N} />
      <Breadcrumb link={"/" + dictionary.Product.Product} title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Product.Product} name={dictionary.Product.N} />
      <div className="faq container text-center"><h1>{dictionary.Product.Product}: {id}</h1></div>
      {data.map((product) => (
        <div className="container" key={product._id.$oid}>
          {product.product.quantity < 1 ?
            <div className="sold-out">
              <img
                src={'/static/images/products/' + product.product?.images.main.src}
                className="pietro text-center"
                alt={product.product.alt}
              />
              <h1 className="text-center">{dictionary.Product.SoldOut}</h1>
            </div>
            :
            <div>
              <Link to={'/product/' + product.product.name?.['en']}>
                <img
                  src={'/static/images/products/' + product.product?.images.main.src}
                  className="card-img-top pietro zoom"
                  alt={product.images?.main.alt?.[defaultLanguage]}
                />
              </Link>
            </div>
          }
          <div className="card-body">
            <h5 className="card-title text-center cardName">
              {product.product.name[defaultLanguage]}
            </h5>
            <h5 className="card-title text-center hide">
              {product.product.name[defaultLanguage]}
            </h5>
            <h6 className="card-subtitle text-center">
              {product.product.subtitle}
            </h6>
            <p className="card-text text-center">
              {product.product.body[defaultLanguage]}...
            </p>
            <div className="row">
              <div className="col text-center">{formatCurrency((product.product.cost * product.product.margin / 100 + product.product.cost) * currencyFactor, currencySymbol)}</div>
            </div>
            {product.product.quantity > 0 ?
              <div className="row">
                <div className="col text-center">
                  {getItemQuantity(product._id.$oid) === 0 ? (
                    <Button className="w-100 btncolor btnsize" onClick={() => increaseCartQuantity(product._id.$oid)}>
                      {dictionary.Cart.AddToCart}
                    </Button>
                  ) : (
                    <div
                      className="d-flex align-items-center flex-column"
                      style={{ gap: ".5rem" }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: ".5rem" }}
                      >
                        <Button className="btncolor" onClick={() => decreaseCartQuantity(product._id.$oid)}>-</Button>
                        <div>
                          <span className="fs-3">{getItemQuantity(product._id.$oid)}</span> in cart
                        </div>
                        <Button className="btncolor" onClick={() => increaseCartQuantity(product._id.$oid)}>+</Button>
                      </div>
                      <Button
                        onClick={() => removeFromCart(product._id.$oid)}
                        variant="secondary"
                        size="sm"
                      >
                        {dictionary.Cart.DelFrCart}
                      </Button>
                      <Button
                        onClick={openCart}
                        style={{ width: "3rem", height: "3rem", position: "relative" }}
                        className="rounded-circle btncolor"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          fill="currentColor"
                        >
                          <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                        </svg>

                        <div
                          className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                          style={{
                            color: "white",
                            width: "1.5rem",
                            height: "1.5rem",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            transform: "translate(25%, 25%)",
                          }}
                        >
                          {cartQuantity}
                        </div>
                      </Button>
                    </div>)}
                </div>
                <div className="outer">
                  <div className="inner">
                    <img className="secondary zoom"
                      src={'/static/images/products/' + product.product?.images.secondary.src}
                      alt={product.images?.secondary.alt?.[defaultLanguage]}
                    />
                  </div>
                  <div className="inner">
                    {product.product.images?.other?.src?.map((item, index) => (
                      <div key={index}>
                        <img className="other-imgs zoom" src={'/static/images/products/' + product.product?.images?.other?.src[index]}
                          alt={product.product?.images?.other?.alt?.[defaultLanguage]} /></div>
                    ))}
                  </div>
                </div>
              </div> : <div className="d-flex align-items-center justify-content-center">
                <Button className="w-100 btn-danger sold-out btnsize">{dictionary.Product.SoldOut}</Button>
              </div>}</div></div>))
      }
      <Footer name="Product" />

    </>
  );
  else {
    return (
      <>
        <Navbar title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Product.Product} name={dictionary.Product.Product} />
        <Breadcrumb title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Product.Product} name={dictionary.Product.Product} />
        <div className="faq container text-center"><h1>{dictionary.Product.AllProducts}</h1></div>
        {data.map((product) => (
          <div className="container" key={product._id.$oid}>
            <Link to={'/product/' + product.product.name?.["en"]}>
              <img
                src={'/static/images/products/' + product.product?.images?.main.src}
                className="card-img-top pietro"
                alt={product.product.images.main.alt?.[defaultLanguage]}
              />
            </Link>
            <div className="outer">
              <div className="inner">
                <img className="secondary zoom"
                  src={'/static/images/products/' + product.product?.images.secondary.src}
                  alt={product.product.images?.secondary.alt?.[defaultLanguage]}
                />
              </div>
              <div className="inner">
                {product.product.images?.other?.src?.map((item, index) => (
                  <div key={index}>
                    <img className="other-imgs zoom" src={'/static/images/products/' + product.product?.images?.other?.src[index]}
                      alt={product.product?.images?.other?.alt?.[defaultLanguage]} /></div>
                ))}
              </div>
            </div>

            <div className="card-body">
              <h5 className="card-title text-center cardName">
                {product.product.name[defaultLanguage]}
              </h5>
              <h5 className="card-title text-center hide">
                {product.product.name[defaultLanguage]}
              </h5>
              <h6 className="card-subtitle text-center">
                {product.product.subtitle}
              </h6>
              <p className="card-text text-center">
                {product.product.body[defaultLanguage]}...
              </p>
              <div className="row">
                <div className="col text-center">{formatCurrency((product.product.cost * product.product.margin / 100 + product.product.cost) * currencyFactor, currencySymbol)}</div>
              </div>
              <div className="row">
                <div className="col text-center">
                  {product.product.quantity > 0 ?
                    <div className="row">
                      <div className="col text-center">
                        {getItemQuantity(product._id.$oid) === 0 ? (
                          <Button className="w-100 btncolor btnsize" onClick={() => increaseCartQuantity(product._id.$oid)}>
                            {dictionary.Cart.AddToCart}
                          </Button>
                        ) : (
                          <div
                            className="d-flex align-items-center flex-column"
                            style={{ gap: ".5rem" }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ gap: ".5rem" }}
                            >
                              <Button className="btncolor" onClick={() => decreaseCartQuantity(product._id.$oid)}>-</Button>
                              <div>
                                <span className="fs-3">{getItemQuantity(product._id.$oid)}</span> in cart
                              </div>
                              <Button className="btncolor" onClick={() => increaseCartQuantity(product._id.$oid)}>+</Button>
                            </div>
                            <Button
                              onClick={() => removeFromCart(product._id.$oid)}
                              variant="secondary"
                              size="sm"
                            >
                              {dictionary.Cart.DelFrCart}
                            </Button>
                            <Button
                              onClick={openCart}
                              style={{ width: "3rem", height: "3rem", position: "relative" }}
                              className="rounded-circle btncolor"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                fill="currentColor"
                              >
                                <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                              </svg>

                              <div
                                className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                                style={{
                                  color: "white",
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  position: "absolute",
                                  bottom: 0,
                                  right: 0,
                                  transform: "translate(25%, 25%)",
                                }}
                              >
                                {cartQuantity}
                              </div>
                            </Button>

                          </div>

                        )

                        }



                      </div>
                    </div>
                    :
                    <div className="d-flex align-items-center justify-content-center">
                      <Button className="w-100 btn-danger sold-out btnsize">{dictionary.Product.SoldOut}</Button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>))}
        <Footer name="Product" />
      </>
    )
  }


};
export default Product;

