import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./context/ShoppingCartContext"
import { createRoot } from 'react-dom/client';
// LANGUAGE
import { LanguageProvider } from './containers/Language';
import LanguageSelector from './components/LanguageSelector';
// CURRENCY
import { CurrencyProvider } from './containers/Currency';
import CurrencySelector from './components/CurrencySelector';
// CSS
import "./style/Index.css";
//SCRAP
import Import from "./pages/Import";

// PAGES
import Home from "./pages/Home";
import Checkout from "./pages/Checkout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Ticket from "./pages/Ticket";
import Faq from "./pages/Faq";
import Faqs from "./pages/Faqs";
import Author from "./pages/Author";
import Refund from "./pages/Refund";
import Privacy from "./pages/Privacy";
import Product from "./pages/Product";
import Category from "./pages/Category";
import Terms from "./pages/Terms";


if (localStorage.getItem("netsystems-currency-fx") === null) {
  window.localStorage.setItem('netsystems-currency-fx', '1')
  window.localStorage.setItem('netsystems-currency', 'BRL')
  window.localStorage.setItem('netsystems-languages', "pt")
}
const container = document.getElementById('root');
const root = createRoot(container!)

root.render
  (
    <BrowserRouter>
      <LanguageProvider>
        <CurrencyProvider>
          <div className="footer-container">
            <div className="div-left">
              <CurrencySelector />
            </div>
            <div className="div-right">
              <LanguageSelector />
            </div>
          </div>
          <ShoppingCartProvider>
            <Routes>
              {/* Default / PT-BR */}
              <Route index element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/product" element={<Product />} />
              <Route path="/product/:id" element={<Product />} />
              <Route path="/category" element={<Category />} />
              <Route path="/import" element={<Import />} />
              <Route path="/category/:id" element={<Category />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/ticket" element={<Ticket />} />
              <Route path="/author/:id" element={<Author />} />
              <Route path="/author" element={<Author />} />
              <Route path="/faq/:id" element={<Faqs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </ShoppingCartProvider>
        </CurrencyProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
