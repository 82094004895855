import React, { useState, useEffect } from "react";
import LocationNavList from "./components/LocationNavList";
import axios from "axios";

function ProductNav() {
  const [location, setLocation] = useState([]);

  useEffect(() => {
    fetchLocation();
  }, []);
  const fetchLocation = () => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => {
        console.log(err);
      }
      );
  }

  return <LocationNavList location={location} />;
}


export default ProductNav;
